<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col lg="6" sm="6">
                                    <ValidationProvider name="Fee" vid="fee_amount" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="fee_amount"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('elearning_config.fee_amount')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            type="number"
                                            id="fee_amount"
                                            :placeholder="$t('elearning_config.fee_amount')"
                                            v-model="roomRentData.fee_amount"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="6">
                                    <ValidationProvider name="effective_date" vid="effective_date">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="effective_date"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('elearning_config.effective_date')}}
                                        </template>
                                        <b-form-input
                                            class="fromDate"
                                            v-model="roomRentData.effective_date"
                                            :placeholder="$t('globalTrans.select_date')"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { duplicateCertificateFeeStore, duplicateCertificateFeeUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getRoomRentData()
            this.roomRentData = tmp
        } else {
            this.roomRentData = Object.assign({}, this.roomRentData, {
                org_id: this.$store.state.dataFilters.orgId,
                fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
            })
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    data () {
        return {
        loading: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        roomRentData: {
            fee_amount: '',
            effective_date: ''
        },
        officeTypeList: [],
        officeList: [],
        guestHouseList: [],
        roomTypeList: []
        }
    },
    computed: {
        orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        // roomTypeList: function () {
        //   return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
        // },
        fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        }
    },
    watch: {
        'roomRentData.org_id': function (newValue) {
            this.officeTypeList = this.getOfficeTypeList(newValue)
        },
        'roomRentData.office_type_id': function (newValue) {
            this.officeList = this.getOfficeList(newValue)
        },
        'roomRentData.office_id': function (newVal) {
            this.guestHouseList = this.guestList(newVal)
        },
        'roomRentData.guest_house_id': function (newVal) {
            this.roomTypeList = this.getRoomTypeList(newVal)
        }
    },
    methods: {
        getRoomRentData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getOfficeTypeList (orgId) {
            const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
            if (orgId) {
                return office.filter(item => item.org_id === orgId)
            }
            return office
        },
        getOfficeList (officeTypeId = null) {
            const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
            if (officeTypeId) {
                return officeList.filter(office => office.office_type_id === officeTypeId)
            }
            return officeList
        },
        guestList (officeId) {
            const guest = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
            if (officeId) {
                return guest.filter(item => item.office_id === officeId)
            }
            return guest
        },
        getRoomTypeList (guestHouseId) {
            const guest = this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
            if (guestHouseId) {
                return guest.filter(item => item.guest_house_id === guestHouseId)
            }
            return guest
        },
        async createData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }

            if (this.roomRentData.id) {
                result = await RestApi.putData(trainingElearningServiceBaseUrl, `${duplicateCertificateFeeUpdate}/${this.id}`, this.roomRentData)
            } else {
                result = await RestApi.postData(trainingElearningServiceBaseUrl, duplicateCertificateFeeStore, this.roomRentData)
            }

            loadinState.listReload = true

            this.$store.dispatch('mutateCommonProperties', loadinState)

            if (result.success) {
                this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
            this.loading = false
        }
    }
}
</script>
